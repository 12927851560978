.footer {
    @apply relative overflow-hidden bg-gradient-to-b from-[#38b2ff] to-[#0073d6] text-white  before:bg-[url('/public/images/footer-decor-left.svg')] mobile:before:bg-[url('/public/images/footer-decor-left-m.svg')] before:w-[632px] mobile:before:w-[344px] before:h-[403px] mobile:before:h-[278px] before:absolute before:bottom-0  before:left-0 after:bg-[url('/public/images/footer-decor-right.svg')] mobile:after:bg-[url('/public/images/footer-decor-right-m.svg')] after:w-[509px] mobile:after:w-[180px] after:h-[445px] mobile:after:h-[144px] after:absolute after:top-0  after:right-0;

    .below {
        @apply relative flex justify-between max-w-[1132px] mx-auto -mt-[82px] mobile:-mt-7 pb-10 px-4 z-10;
    }

    .contacts {
        @apply mt-auto max-w-[494px] mobile:max-w-none mobile:mx-auto mobile:mb-7 mobile:mt-4 flex mobile:block whitespace-nowrap;
    }
}

.contacts {
    li {
        @apply flex w-[300px] mobile:w-[320px] first:max-w-[148px] bg-white rounded-lg p-2 text-[#1E282D] last:rounded-tr-none mobile:last:rounded-tr-lg mobile:first:max-w-none;

        & + li {
            @apply ml-4 mobile:ml-0 mobile:mt-4;
        }
    }

    svg {
        @apply min-w-[24px] -mt-[2px] block;
    }

    h4 {
        @apply font-semibold text-sm;
    }

    a {
        @apply text-xs font-medium;

        & + a {
            @apply mobile:block before:content-['|'] before:text-[#0F85FF] before:px-2 mobile:before:content-none;
        }
    }
}

.contacts_unstyled {
    @apply pl-9;
    li {
        @apply flex text-base font-medium;

        & + li {
            @apply mt-10;
        }

        h4 {
            @apply mt-[2px] font-semibold mb-4;
        }

        a {
            @apply inline-block;
        }
    }
}

.author {
    @apply text-[0] flex justify-between mt-auto;

    a {
        @apply opacity-50 transition-opacity duration-300 hover:opacity-100;
    }

    .line {
        @apply w-[2px] h-5 bg-[rgba(255,255,255,0.5)] rounded-full mx-3;
    }
}

.docs {
    @apply min-w-[182px] text-[12px] border-r mobile:border-none mobile:order-last;
}

.bot {
    @apply right-0 -top-[87px] absolute pointer-events-none;
}
