body {
    &[data-theme="internal"] {
        .header {
            @apply before:h-headerHeight mobile:before:h-headerHeightMob before:absolute before:top-0 before:right-0 before:bg-[#1A4C6C] before:left-0;
        }
    }
}

.container {
    @apply mx-auto flex justify-between max-w-layout pt-7 mobile:pt-5 relative overflow-hidden;
}

.header {
    @apply relative h-0 z-20;
}

.nav {
    @apply list-none mobile:mt-10;

    li {
        @apply text-white mr-10 mobile:mr-0 last:mr-0 inline-block text-sm font-medium mobile:text-2xl mobile:font-medium;
    }

    .active {
        @apply hidden;
    }
}

.contacts {
    @apply w-auto;
}

@media (max-width: 767px) {
    .responsive {
        @apply overflow-hidden -translate-x-full absolute inset-0 z-[200] before:fixed before:top-0 before:z-[-1] before:inset-0 before:bg-[#1E282D]/50 transition-all;

        &:before {
            backdrop-filter: blur(12.5px);
        }
    }

    .responsiveWrap {
        @apply flex flex-col w-[240px] h-full bg-[#1A4C6C] pt-1 pb-16;

        .nav {
            @apply pl-9;
        }
    }

    .responsive_active {
        @apply translate-x-0;
    }

    .link {
        @apply mx-auto;
    }

    .logo {
        @apply w-[70px] h-[16px] block;
    }

    .nav li {
        @apply w-full text-xl text-center mb-4 mobile:text-left;
    }

    .info {
        @apply text-white text-xs mt-auto mx-auto relative;
    }

    .info li:not(:last-child) {
        @apply mt-2;
    }
}

@media (max-width: 1100px) {
    .header {
        @apply px-4;
    }
}
