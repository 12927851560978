@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Ubuntu:wght@700&display=swap");

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Poppins", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1e282d;
}

.nav-open {
    overflow: hidden;
}

.root,
#root,
#docs-root {
    --gradient: linear-gradient(180deg, #38b2ff 0%, #0073d6 100%);
    --sectionGradient: linear-gradient(180deg, #38b2ff 0%, #0073d6 100%);
    --headerHeight: 80px;
    --headerHeightMob: 60px;
}

.nav-open {
    overflow: hidden;
    height: 100%;
    touch-action: none;
    -ms-touch-action: none;
}

/* Ham */
@media (max-width: 767px) {
    .ham {
        width: 40px;
        height: 40px;
        position: absolute;
        right: -13px;
        bottom: -12px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: transform 400ms;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .hamRotate.active {
        transform: rotate(45deg);
    }
    .hamRotate180.active {
        transform: rotate(180deg);
    }
    .line {
        fill: none;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke: #ffffff;
        stroke-width: 4;
        stroke-linecap: round;
    }
    .ham .top {
        stroke-dasharray: 20 160;
    }
    .ham .middle {
        stroke-dasharray: 40 142;
        transform-origin: 50%;
        transition: transform 400ms;
    }
    .ham .bottom {
        stroke-dasharray: 20 85;
        transform-origin: 50%;
        transition: transform 400ms, stroke-dashoffset 400ms;
    }
    .ham.active .top {
        stroke-dashoffset: -64px;
    }
    .ham.active .middle {
        transform: rotate(90deg);
    }
    .ham.active .bottom {
        stroke-dashoffset: -64px;
    }
}

.section-heading {
    position: relative;

    background: linear-gradient(180deg, #38b2ff 0%, #0073d6 86.04%);
}

.section-heading h1,
.section-heading h2,
.section-heading h3 {
    color: #fff;
}

.section-heading h1 {
    margin: 50px 0 24px;

    font-family: Ubuntu;
    font-size: 72px;
    font-weight: 700;
    line-height: 88px;
    color: #fff;

    @media (max-width: 767px) {
        margin: 0;

        font-size: 40px;
        line-height: 60px;
    }
}

.section-heading h2 {
    font-size: 40px;

    @media (max-width: 767px) {
        font-size: 24px;
    }
}

.section-heading h3 {
    font-size: 24px;

    @media (max-width: 767px) {
        font-size: 16px;
    }
}

.section-heading .wide-container {
    padding-bottom: 55px;
}

.section-heading__decor {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;

    width: auto;
    height: 100%;
}

.section-content .full-width {
    max-width: none;
}

.btn_outline:hover {
    background-color: #1e282d;
    color: #fff;
}

.btn_outline:hover .icon-fill {
    fill: #fff;
}
