.contacts {
    display: flex;
    margin-top: 30px;
    max-width: 494px;

    @media (max-width: 767px) {
        display: block;
        margin: 1rem auto 1.75rem;
        max-width: none;
    }

    li {
        display: flex;

        width: 300px;

        border-radius: 8px;

        background-color: #fff;

        padding: 8px;

        color: #1e282d;

        @media (max-width: 767px) {
            width: 320px;
        }

        &:first-child {
            max-width: 148px;

            @media (max-width: 767px) {
                max-width: none;
            }
        }

        &:last-child {
            border-top-right-radius: 0px;

            @media (max-width: 767px) {
                border-top-right-radius: 8px;
            }
        }

        & + li {
            margin-left: 16px;

            @media (max-width: 767px) {
                margin-top: 16px;
                margin-left: 0;
            }
        }
    }

    svg {
        display: block;
        min-width: 24px;
        margin-top: -2px;
    }

    h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    a {
        display: inline-block;
        width: 50%;
        position: relative;
        padding: 4px 0;
        vertical-align: top;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;

        @media (max-width: 767px) {
            display: block;
        }

        &:nth-child(2n) {
            padding-left: 8px;

            @media (max-width: 767px) {
                padding-left: 0;
            }

            &:before {
                content: "";
                display: inline-block;

                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;

                width: 1px;

                background-color: #0f85ff;

                @media (max-width: 767px) {
                    content: none;
                }
            }
        }
    }
}

.contacts_unstyled {
    padding-left: 36px;

    li {
        display: flex;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;

        & + li {
            margin-top: 40px;
        }

        h4 {
            margin-top: 2px;
            margin-bottom: 16px;

            font-weight: 600;
        }

        a {
            display: inline-block;
        }
    }
}

.contact-list {
    max-width: 342px;
    margin-top: 40px;

    @media (max-width: 767px) {
        max-width: none;
    }

    li {
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(30, 40, 45, 0.2);
        font-size: 14px;

        & + li {
            margin-top: 8px;
            @media (max-width: 767px) {
                margin-top: 16px;
            }
        }

        > div {
            display: flex;
            align-items: center;
            padding-left: 0;

            @media (max-width: 767px) {
                display: block;
            }
        }

        svg {
            display: none;
        }

        h4 {
            width: 114px;
            font-weight: 600;

            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
        }

        a {
            display: block;
        }
    }
}
